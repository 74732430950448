/**
 * Color variables used in various styles 
 */
:root {
    --main-color: #2185d0;
    --secondary-color: #1678c2;
    --background-color: #bfd1e5;
    --light-gray: #f1f1f1;
}

body {
    overflow: hidden;
}

.App {
    text-align: center;
}

* {
    box-sizing: border-box;
}
html, body, #root{
    height: 100vh;
}


#vrCanvas > canvas {
    display: block;
    height: calc(100vh - 64px) !important;
}

#coords {
    display: flex;
    align-self: center;
}

#scene {
  width: inherit;
  position: absolute;
}


/**
 * The basic colored buttons
 */
.c-button {
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    color: white;
    margin: 5px 3px 3px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: inline-block;
    font-size: 14px;
    min-height: 30px;
    min-width: 50px;
}

/**
 * Buttons with icons in them
 */
.i-button {
    background-color: var(--main-color);
    border: none;
    border-radius: 5px;
    color: white;
    margin: 3px;
    display: inline-block;
    font-size: 16px;
    min-height: 30px;
    min-width: 50px;
}

/**
 * Class for keeping elements at the bottom of the screen
 */
.button-box {
    position: absolute;
    justify-content: center;
    padding: 5px;
    bottom: 10%;
    right: 0;
    width: 100%;
}


/**
 * Class for the information box that appears when a user hovers over a robot
 */
.hover-info {
    background-color: var(--main-color);
    border-style: solid;
    border-width: 3px;
    border-radius: 7px;
    border-color: var(--secondary-color);
    width: 200px;
    display: none;
    position: fixed;
    padding: 6px 12px 6px 0;
    z-index: 99;
}


/**
 * Used to toggle when elements appear
 */
.show {
    display: block
}

.hide {
    display: none
}

.invisible {
    visibility: hidden
}

/**
 * General class used for positioning elements in a row
 */
.flex-row {
    width:100%;
    display: flex;
    flex-direction: row;
}

.inline-flex-row{
    display: inline-flex;
    flex-direction: row;
}

/**
 * Additional styling for rows in the 'Section tool' dropdown
 */
.tool-form-row {
    justify-content: space-between;
    padding: 0 25px 3px;
}

/**
 * General class used for positioning elements in a row
 */
.flex-col {
    width:100%;
    display: flex;
    flex-direction: column;
}

.inline-flex-col{
    display: inline-flex;
    flex-direction: column;
}

/**
 * General class for justifying content
 */
.just-bet {
    display: flex;
    justify-content: space-between;
}

.just-around {
    display: flex;
    justify-content: space-around;
}

/**
 * Classes used to display current color of robots
 */
.robot-color-box {
    align-self: center;
    width: 100px;
    height: 80px;
    border: white 2px solid;
    border-radius: 10px;
}

.rob-color {
    width: 20%;
    display: none;
    position: relative;
    padding: 0;
}

/**
 * Styles to overlay color input with clickable icon
 */
.color-button {
    width: 20%;
    background: none;
    border: none;
    border-radius: 2px;
    color: white;
    cursor: pointer;
    margin: 3px 1px 1px;
}

.color-icon {
    display: inline-block;
    position: relative;
    background-color: var(--main-color);
    padding: 3px;
    margin: 0;
    left: -6px;
    border-radius: 30px;
    border: 2px solid white;

}

.button-icon {
    font-size: 32px;
}


.level-icon {
    align-self: center;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    font-style: italic;
}

/**
 * Classes for loading animation that appears on load
 */
#loading-container {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-content: center;
}

#loading-container > * {
    position: fixed;
}

.loading-net-progress {
    position: fixed;
    left: 51%;
    top: 72px;
}

.loading-net-progress-percent {
    position: fixed;
    left: 52%;
    top: 100px;
}

.loading-net-progress-text {
    position: fixed;
    left: 57%;
    top: 100px;
}

#loading-container{
  width:inherit;
}

.loading-pos-progress {
    align-self: center;
    transform: translate(-50%, -50%);
}

.loading-pos-progress-percent {
    align-self: center;
    transform: translate(-40%, -0%);
    font-family: 'Comic Sans MS', serif;
}


/**
 * Styles to replace date and time inputs
 */
input[type='date']::-webkit-clear-button,
input[type='time']::-webkit-clear-button {
    display: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='time']::-webkit-inner-spin-button {
    display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
    color: var(--main-color);
}

input[type='date'],
input[type='time'] {
    appearance: none;
    -webkit-appearance: none;
    color: white;
    font-family: 'Helvetica', arial, sans-serif;
    font-size: 18px;
    border: none;
    background: var(--main-color);
    display: inline-block !important;
    visibility: visible !important;
}

input[type='date'],
input[type='time'] focus {
    color: white;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
